export const menuItems = [
  {
    id: 1,
    label: 'menuitems.dashboard.text',
    icon: 'ri-dashboard-line',
    link: '/'
  },
  {
    id: 23,
    label: 'menuitems.calendar.text',
    link: '/calendar'
  },
  {
    id: 24,
    label: 'menuitems.chat.text',
    link: '/chat'
  },
  {
    id: 37,
    label: 'menuitems.patients.text',
    link: '/patients-board'
  },
]
