export const menuItems = [{
  id: 1,
  label: 'menuitems.menu.text',
  isTitle: true
}, {
  id: 2,
  label: 'menuitems.dashboard.text',
  icon: 'fa-solid fa-desktop',
  badge: {
    variant: 'success',
    text: 'menuitems.dashboard.badge'
  },
  link: '/'
}, {
  id: 3,
  label: 'menuitems.patients.text',
  icon: 'fa-solid fa-users',
  link: '/patients-board',
  feature: 'exercises'
}, {
  id: 10,
  label: 'menuitems.pedisteps.text',
  icon: 'fa-solid fa-desktop',
  link: '/pedisteps',
  feature: 'pedisteps'
}, {
  id: 4,
  label: 'menuitems.chat.text',
  icon: 'fa-solid fa-message',
  link: '/chat',
  feature: 'chat'
}, {
  id: 5,
  label: 'menuitems.calendar.text',
  icon: 'fa-solid fa-calendar',
  link: '/calendar',
  feature: 'calendar'
}, {
  id: 6,
  label: 'menuitems.creator.text',
  icon: 'fa-solid fa-paintbrush',
  link: '/creator',
  badge: {
    variant: 'danger',
    text: 'menuitems.alpha.badge'
  },
  feature: 'creator',
  disabled: true
}, {
  id: 7,
  label: 'menuitems.marketplace.text',
  icon: 'fa-solid fa-store',
  link: '/marketplace',
  badge: {
    variant: 'danger',
    text: 'menuitems.alpha.badge'
  },
  feature: 'marketplace',
  disabled: true
}, {
  id: 8,
  label: 'World Map',
  icon: 'fa-solid fa-map',
  link: '/admin/map',
  feature: 'map',
  admin: true,
}, {
  id: 9,
  isLayout: true
},]
